import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 2000.000000 2000.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)" >
<path d="M7450 12590 l0 -50 45 0 c43 0 49 -4 104 -62 32 -35 185 -200 340
-368 273 -294 284 -305 320 -305 35 0 48 12 379 368 341 364 343 367 383 367
l40 0 -3 48 -3 47 -337 3 -338 2 0 -50 0 -50 63 0 c84 0 130 -14 172 -54 29
-28 35 -40 35 -75 0 -37 -7 -49 -81 -131 l-81 -90 -107 101 c-122 117 -141
142 -141 186 0 42 23 63 71 63 l39 0 0 50 0 50 -450 0 -450 0 0 -50z m566
-341 c148 -160 271 -295 273 -301 13 -37 -57 31 -285 277 -302 327 -292 315
-272 315 8 0 136 -131 284 -291z"/>
<path d="M9070 12450 l0 -190 33 0 c24 0 37 8 53 31 84 120 229 260 253 244 7
-4 11 -98 11 -281 l0 -275 -25 -24 c-23 -24 -31 -25 -135 -25 l-110 0 0 -50 0
-50 535 0 535 0 0 49 0 49 -116 4 c-165 4 -154 -19 -154 324 0 179 4 275 10
279 24 15 126 -80 223 -207 50 -65 53 -68 94 -68 l43 0 0 190 0 190 -625 0
-625 0 0 -190z m518 -218 c3 -298 2 -302 -18 -302 -20 0 -20 5 -20 306 0 286
1 305 18 302 16 -3 17 -27 20 -306z"/>
<path d="M10387 12633 c-4 -3 -7 -25 -7 -49 l0 -42 65 -4 c51 -3 70 -9 88 -26
21 -22 22 -31 27 -275 3 -169 9 -259 17 -273 25 -43 91 -92 154 -112 91 -30
173 -42 339 -49 296 -12 460 38 513 156 18 40 21 70 24 236 4 202 12 256 46
293 24 26 103 52 159 52 l38 0 0 50 0 50 -297 -2 -298 -3 -3 -47 c-3 -46 -2
-48 23 -48 54 0 118 -23 149 -55 48 -48 56 -90 56 -294 l0 -181 -32 -35 c-43
-46 -129 -68 -239 -63 -66 3 -82 7 -101 26 -23 22 -23 27 -26 281 l-3 259 24
27 c18 22 34 29 75 33 l52 5 0 48 0 49 -418 0 c-230 0 -422 -3 -425 -7z m337
-364 c4 -149 4 -274 1 -277 -3 -3 -11 0 -18 7 -9 9 -13 83 -15 276 -2 221 -1
265 11 265 11 0 15 -50 21 -271z"/>
<path d="M11948 11820 c-9 -6 -31 -55 -50 -110 l-32 -100 -106 0 c-112 0 -130
-6 -130 -46 0 -17 22 -39 85 -84 47 -34 85 -65 85 -69 0 -4 -15 -53 -34 -109
l-35 -102 25 -17 c24 -15 27 -15 95 33 123 86 112 83 161 48 135 -97 134 -97
157 -85 28 16 27 40 -9 146 -16 49 -30 90 -30 91 0 1 38 29 84 63 85 61 100
79 90 106 -8 20 -31 25 -143 28 l-94 2 -31 91 c-29 85 -49 124 -65 124 -3 0
-14 -5 -23 -10z m42 -235 c15 -54 17 -56 84 -64 l51 -6 -38 -23 c-45 -29 -53
-54 -32 -111 8 -24 14 -46 12 -48 -2 -1 -21 10 -43 27 -47 36 -68 37 -108 7
-55 -41 -56 -41 -39 8 20 59 11 88 -34 117 l-38 23 51 5 c63 7 78 18 94 75 7
25 16 43 20 40 4 -2 13 -25 20 -50z"/>
<path d="M8405 11544 c-22 -3 -97 -13 -166 -24 -69 -11 -152 -21 -185 -21
l-59 -2 55 -7 c30 -4 82 -15 115 -24 39 -10 107 -17 200 -18 121 -1 109 -3
-85 -8 -135 -4 -236 -11 -253 -18 -16 -7 -57 -12 -93 -12 -36 0 -64 -4 -64
-10 0 -6 26 -10 59 -10 32 0 78 -7 101 -15 29 -10 87 -15 189 -16 110 -1 134
-4 96 -9 -27 -5 -107 -9 -176 -9 -85 -1 -141 -6 -169 -16 -23 -8 -72 -16 -109
-16 l-66 -2 66 -7 c37 -5 76 -13 87 -19 11 -6 39 -11 62 -11 25 0 39 -4 35
-10 -3 -5 -19 -10 -34 -10 -16 0 -48 -7 -71 -15 -23 -8 -72 -16 -109 -17 -41
-1 -52 -3 -28 -5 20 -2 37 -7 37 -11 0 -4 -152 -54 -337 -111 -186 -57 -385
-119 -443 -136 -58 -18 -145 -45 -195 -60 -49 -14 -108 -32 -130 -40 -22 -7
-80 -25 -130 -40 -99 -29 -175 -55 -175 -60 0 -13 -105 -567 -160 -840 -5 -27
-26 -138 -45 -245 -19 -107 -42 -226 -50 -265 -8 -38 -17 -81 -19 -95 -37
-206 -125 -675 -152 -810 -19 -96 -61 -317 -94 -490 -32 -173 -77 -412 -100
-530 -23 -118 -45 -237 -50 -265 -5 -27 -25 -135 -45 -240 -20 -104 -39 -201
-41 -215 -3 -14 -11 -56 -19 -95 -28 -133 -96 -517 -92 -521 2 -2 621 816
1377 1819 l1373 1822 24 120 c12 66 34 174 48 240 13 66 38 194 55 285 42 221
39 207 56 278 7 35 26 88 40 119 22 45 24 57 13 64 -10 6 -10 9 -2 9 9 0 12
21 12 73 -1 39 8 119 20 177 11 58 21 106 21 108 0 1 8 2 18 2 16 0 27 15 60
82 2 4 -12 8 -30 11 l-33 4 32 1 c26 2 38 10 58 42 14 22 25 42 25 44 0 3 -28
7 -62 9 l-63 3 67 2 c56 2 69 5 78 22 6 11 8 24 5 29 -14 23 -245 46 -350 35z"/>
<path d="M8960 11514 c-25 -7 -73 -14 -107 -14 -35 0 -63 -4 -63 -10 0 -5 26
-10 58 -10 31 0 79 -7 106 -15 39 -12 116 -15 382 -15 l332 0 21 34 c12 18 21
36 21 40 0 3 -159 6 -352 5 -275 0 -363 -3 -398 -15z"/>
<path d="M10120 11514 c-25 -7 -74 -14 -110 -15 l-65 -2 60 -7 c33 -4 82 -14
108 -23 37 -13 86 -17 227 -17 98 0 181 3 183 8 3 4 8 22 12 40 l7 32 -189 -1
c-133 0 -201 -4 -233 -15z"/>
<path d="M11035 11519 c-22 -6 -76 -14 -120 -18 -69 -7 -73 -8 -28 -9 29 -1
75 -11 103 -22 47 -18 74 -20 356 -20 332 0 343 2 249 50 -49 24 -56 25 -285
27 -129 1 -253 -3 -275 -8z"/>
<path d="M8910 11424 c-25 -7 -74 -14 -110 -15 l-65 -2 65 -7 c36 -5 82 -15
102 -24 32 -14 90 -16 380 -16 l343 0 18 31 c9 17 17 35 17 40 0 5 -144 9
-352 8 -275 0 -363 -3 -398 -15z"/>
<path d="M10050 11425 c-30 -8 -84 -15 -120 -16 l-65 -2 60 -7 c33 -4 77 -15
97 -24 31 -13 77 -16 262 -16 243 0 236 -2 236 56 l0 24 -207 -1 c-140 0 -226
-5 -263 -14z"/>
<path d="M10950 11424 c-25 -7 -72 -14 -105 -15 l-60 -2 65 -7 c36 -5 81 -14
100 -20 23 -9 144 -15 360 -19 l325 -7 -341 -2 c-274 -2 -350 -5 -384 -17 -23
-8 -72 -16 -109 -17 l-66 -1 60 -7 c33 -4 80 -14 104 -23 24 -10 72 -17 108
-17 40 0 62 -4 58 -10 -3 -5 -36 -10 -73 -10 -38 0 -94 -7 -125 -15 -32 -8
-86 -16 -120 -17 -50 -1 -53 -2 -17 -5 25 -1 70 -10 100 -18 30 -9 100 -19
155 -24 l100 -8 -111 -2 c-66 0 -131 -7 -160 -16 -27 -8 -78 -15 -114 -16
l-65 -2 65 -7 c36 -4 78 -12 93 -19 16 -6 85 -11 160 -11 73 0 139 -5 147 -10
10 -6 -35 -10 -136 -10 -105 0 -166 -5 -200 -15 -27 -8 -78 -15 -114 -16 l-65
-2 65 -7 c36 -3 72 -10 80 -16 8 -5 101 -14 205 -20 l190 -11 -182 -2 c-156
-1 -189 -4 -233 -21 -29 -11 -77 -21 -113 -21 l-62 -2 70 -7 c39 -5 83 -13 98
-19 16 -6 81 -12 145 -13 l117 -1 -95 -9 c-52 -4 -126 -13 -165 -20 -38 -6
-104 -14 -145 -18 -64 -6 -68 -7 -25 -8 28 -1 76 -11 108 -22 44 -16 82 -20
174 -20 107 0 119 2 131 20 19 31 103 27 171 -6 53 -27 53 -27 30 -40 -13 -7
-55 -14 -94 -15 l-70 -2 65 -7 c41 -4 82 -15 111 -31 l46 -26 -30 -16 c-18
-11 -54 -18 -95 -18 l-67 -2 65 -7 c36 -4 79 -15 95 -24 17 -9 41 -16 55 -17
21 -1 19 -2 -10 -11 -19 -5 -39 -14 -44 -19 -6 -5 -37 -10 -70 -11 l-61 -1 60
-6 c33 -4 77 -14 97 -24 30 -14 67 -17 193 -17 l156 0 -7 23 c-3 12 -13 34
-20 49 -8 15 -14 31 -14 35 1 8 -54 149 -85 221 l-15 32 -124 0 c-87 0 -134
-4 -159 -15 -20 -8 -64 -15 -98 -15 -52 0 -60 2 -55 16 3 9 6 20 6 25 0 5 90
8 213 6 197 -2 212 -1 209 16 -2 9 1 17 6 17 11 0 120 73 153 102 106 94 158
256 119 372 l-12 36 -336 -1 c-262 0 -347 -4 -382 -15z m430 -181 c-19 -9 -68
-18 -107 -21 -40 -2 -73 -8 -73 -12 0 -4 28 -10 63 -12 35 -3 76 -13 93 -21
28 -16 24 -16 -94 -17 l-122 0 25 50 25 50 113 -1 c110 -1 111 -1 77 -16z"/>
<path d="M8860 11334 c-25 -7 -73 -14 -107 -14 -35 0 -63 -4 -63 -10 0 -6 26
-10 59 -10 32 0 75 -7 94 -15 30 -12 98 -15 385 -15 l348 0 23 40 22 40 -358
-1 c-280 0 -368 -3 -403 -15z"/>
<path d="M9975 11333 c-27 -9 -84 -19 -125 -23 -66 -6 -69 -7 -24 -8 28 -1 73
-9 100 -17 28 -8 94 -15 154 -16 l105 -2 -85 -8 c-47 -5 -123 -14 -170 -20
-47 -7 -119 -15 -160 -19 -59 -5 -65 -7 -28 -8 26 -1 70 -11 98 -22 41 -16 74
-20 178 -20 82 0 132 4 139 11 9 9 20 9 44 0 17 -6 87 -11 154 -11 119 0 123
1 129 23 16 61 19 60 -134 55 -115 -4 -137 -2 -128 9 8 10 46 13 144 13 121 0
134 2 134 18 0 9 3 27 6 40 l6 22 -243 -1 c-190 -1 -255 -4 -294 -16z"/>
<path d="M9040 11240 c-30 -11 -81 -21 -115 -21 l-60 -2 65 -7 c36 -4 78 -12
93 -19 16 -6 86 -11 163 -11 l134 0 20 33 c11 17 20 35 20 40 0 4 -60 7 -132
7 -106 -1 -144 -5 -188 -20z"/>
<path d="M8985 11144 c-27 -7 -77 -14 -110 -15 l-60 -2 65 -7 c36 -3 76 -13
90 -20 17 -9 70 -15 161 -18 l136 -4 22 35 c11 19 21 37 21 41 0 12 -275 4
-325 -10z"/>
<path d="M9790 11144 c-25 -7 -74 -14 -110 -15 l-65 -2 65 -7 c36 -3 79 -14
97 -23 44 -23 284 -25 303 -2 7 8 23 15 35 15 12 0 39 -7 58 -15 26 -11 73
-15 161 -15 133 0 136 1 136 56 l0 24 -117 0 c-72 0 -139 -6 -173 -15 -68 -18
-65 -18 -50 0 11 13 -6 15 -141 14 -103 0 -169 -5 -199 -15z"/>
<path d="M8927 11048 l-69 -22 49 -12 c26 -8 107 -16 180 -20 l131 -7 21 34
c12 19 21 37 21 42 0 4 -60 7 -132 6 -106 0 -147 -5 -201 -21z"/>
<path d="M10275 11060 c-55 -4 -113 -12 -128 -19 -16 -6 -46 -11 -67 -11 -31
0 -38 3 -34 15 5 13 -15 15 -149 15 -112 0 -166 -4 -197 -15 -23 -8 -70 -16
-104 -16 l-61 -2 72 -7 c39 -4 77 -11 84 -16 8 -4 59 -11 114 -14 l100 -7
-113 -1 c-84 -2 -127 -7 -169 -22 -32 -11 -84 -21 -118 -21 l-60 -2 70 -7 c39
-5 86 -13 105 -19 23 -7 167 -11 421 -11 l387 0 6 23 c16 60 27 57 -221 57
l-227 0 22 22 22 22 84 -17 c53 -11 129 -17 210 -17 113 0 126 2 126 18 0 9 3
27 6 40 7 25 7 25 -181 12z"/>
<path d="M8895 10960 c-29 -12 -78 -21 -115 -21 l-65 -2 70 -7 c39 -4 83 -12
98 -19 16 -6 84 -11 158 -11 l129 0 20 33 c11 17 20 35 20 40 0 4 -60 7 -132
7 -107 -1 -143 -5 -183 -20z"/>
<path d="M8840 10864 c-25 -7 -74 -14 -110 -15 l-65 -2 65 -7 c36 -3 82 -14
102 -23 29 -13 67 -17 163 -17 l125 0 20 33 c11 17 20 35 20 40 0 13 -272 7
-320 -9z"/>
<path d="M9545 10864 c-27 -7 -79 -14 -115 -15 l-65 -2 60 -7 c33 -3 77 -14
99 -23 26 -11 68 -17 130 -18 l91 -2 -128 -8 c-70 -5 -144 -13 -165 -19 -20
-5 -66 -11 -102 -12 l-65 -1 65 -7 c36 -3 78 -12 95 -19 16 -7 91 -15 166 -18
l136 -6 43 46 42 47 292 2 291 3 3 38 3 37 -413 -1 c-320 -1 -424 -4 -463 -15z"/>
<path d="M8800 10775 c-19 -7 -69 -15 -110 -16 l-75 -2 65 -7 c36 -3 82 -14
102 -23 29 -13 67 -17 163 -17 l125 0 20 33 c11 17 20 35 20 40 0 13 -272 7
-310 -8z"/>
<path d="M10090 10774 c-25 -7 -74 -14 -110 -15 l-65 -2 65 -7 c36 -3 82 -14
102 -23 29 -13 68 -17 172 -17 74 0 137 3 139 8 3 4 8 22 12 40 l7 32 -139 -1
c-89 0 -154 -5 -183 -15z"/>
<path d="M10610 10774 c-25 -7 -74 -14 -110 -15 l-65 -2 65 -7 c36 -5 82 -15
102 -24 27 -12 70 -16 160 -16 l123 0 23 35 c12 19 22 37 22 40 0 12 -277 3
-320 -11z"/>
<path d="M8755 10683 c-22 -9 -74 -19 -115 -23 -73 -7 -74 -7 -19 -8 31 -1 76
-9 99 -17 29 -10 82 -15 171 -15 l129 0 20 33 c11 17 20 35 20 40 0 4 -60 7
-132 7 -98 -1 -144 -5 -173 -17z"/>
<path d="M9380 10680 c-26 -11 -73 -20 -110 -21 l-65 -2 65 -7 c36 -5 78 -13
93 -19 16 -6 90 -11 166 -11 l137 0 39 40 39 40 -159 -1 c-133 0 -167 -3 -205
-19z"/>
<path d="M10070 10680 c-30 -11 -81 -21 -115 -21 l-60 -2 65 -7 c36 -4 78 -12
93 -19 17 -6 90 -11 178 -11 126 0 149 2 149 15 0 12 13 15 60 15 33 0 77 -7
97 -15 25 -11 73 -15 168 -15 l133 0 21 36 c12 19 21 37 21 40 0 2 -61 4 -135
4 -111 0 -144 -4 -187 -20 -31 -12 -77 -20 -110 -20 -51 0 -58 2 -58 20 0 19
-7 20 -132 20 -106 -1 -144 -5 -188 -20z"/>
<path d="M9445 10600 c-71 -4 -143 -12 -158 -18 -16 -7 -61 -13 -100 -13 l-72
-2 70 -7 c39 -4 83 -12 98 -19 17 -6 87 -11 172 -11 l143 0 32 40 32 40 -44
-2 c-23 -1 -101 -4 -173 -8z"/>
<path d="M8691 10585 c-18 -8 -63 -16 -100 -16 l-66 -2 65 -7 c36 -5 78 -13
93 -19 16 -6 89 -11 162 -11 l134 0 16 30 c8 16 15 31 15 35 0 3 -64 5 -142 5
-103 -1 -153 -5 -177 -15z"/>
<path d="M10041 10585 c-18 -8 -63 -16 -100 -16 l-66 -2 70 -7 c39 -4 83 -12
98 -19 17 -6 89 -11 178 -11 126 0 149 2 149 15 0 20 50 20 118 0 37 -11 87
-15 179 -13 l127 3 19 33 19 32 -145 0 c-103 0 -157 -4 -187 -15 -23 -8 -62
-15 -86 -15 -32 0 -44 4 -44 15 0 21 -279 21 -329 0z"/>
<path d="M9818 10313 c0 -125 -2 -239 -3 -253 -1 -14 -6 -261 -10 -550 -3
-289 -8 -556 -10 -595 -2 -38 -5 -205 -7 -369 l-3 -300 45 -26 c25 -14 77 -43
115 -64 39 -21 88 -49 110 -61 22 -12 69 -38 105 -57 36 -19 85 -46 110 -60
25 -14 72 -40 105 -58 33 -18 90 -50 127 -71 36 -22 69 -39 71 -39 3 0 58 -29
122 -65 64 -36 118 -65 120 -65 2 0 26 -13 52 -28 26 -16 80 -46 118 -67 39
-21 109 -60 158 -86 48 -27 89 -49 91 -49 3 0 43 -22 91 -50 48 -27 88 -50 90
-50 2 0 26 -13 52 -28 26 -16 93 -52 148 -82 55 -29 171 -93 258 -142 87 -48
159 -88 161 -88 2 0 35 -17 73 -39 37 -21 104 -57 148 -81 44 -24 159 -87 255
-140 170 -94 235 -130 378 -206 37 -21 72 -40 77 -44 6 -4 46 -27 90 -50 44
-24 150 -81 235 -128 85 -47 178 -97 205 -112 28 -15 91 -50 140 -77 50 -28
126 -69 170 -93 44 -23 85 -47 92 -52 7 -6 17 -8 24 -6 6 2 -3 22 -23 48 -18
25 -69 97 -113 160 -44 63 -85 122 -92 130 -7 8 -29 41 -50 72 -21 31 -99 144
-174 250 -75 106 -194 277 -266 379 -71 103 -144 205 -161 227 -18 22 -32 43
-32 47 0 4 -19 33 -42 65 -24 32 -70 97 -103 145 -33 48 -65 93 -71 99 -5 6
-27 38 -48 71 -21 33 -42 62 -46 65 -6 5 -30 39 -140 198 -62 91 -103 149
-270 387 -80 114 -159 228 -177 253 -18 26 -36 52 -41 58 -5 6 -27 37 -48 70
-21 32 -42 61 -45 64 -5 5 -30 40 -134 189 -22 32 -65 93 -95 136 -30 43 -70
101 -89 129 -18 28 -37 53 -41 56 -4 3 -24 32 -45 65 -21 33 -41 62 -46 65 -4
3 -26 33 -48 65 -152 229 -167 247 -233 282 -35 19 -85 47 -113 62 -27 16 -81
45 -120 66 -38 21 -74 41 -80 45 -5 4 -50 29 -100 55 -49 26 -148 80 -220 120
-71 39 -166 91 -210 115 -44 24 -115 63 -157 87 -43 23 -80 43 -82 43 -3 0
-27 13 -53 28 -26 16 -79 46 -118 67 -38 21 -94 51 -122 67 l-53 30 -2 -229z"/>
<path d="M16532 5648 c-5 -7 -26 -66 -46 -130 l-37 -118 -119 0 c-125 0 -163
-8 -174 -35 -10 -28 11 -52 114 -126 55 -39 100 -74 100 -77 0 -4 -19 -63 -41
-131 l-41 -123 20 -21 c27 -26 44 -19 164 69 48 35 90 64 94 64 3 0 46 -29 95
-65 121 -89 134 -94 162 -67 l21 22 -38 117 c-21 65 -41 123 -43 129 -2 5 44
44 102 85 116 82 135 109 95 141 -19 15 -41 18 -150 18 l-127 0 -32 108 c-18
59 -39 117 -47 130 -16 24 -55 30 -72 10z m57 -250 c31 -92 30 -91 117 -97
l78 -6 -57 -39 c-32 -22 -60 -49 -63 -60 -2 -12 4 -48 16 -80 11 -33 18 -61
16 -63 -2 -2 -30 14 -62 37 -65 46 -73 45 -146 -7 -27 -20 -51 -33 -53 -31 -3
3 4 31 15 63 11 32 20 66 20 76 0 11 -25 37 -62 64 l-62 45 61 0 c96 0 108 8
134 89 13 39 24 71 25 71 1 0 12 -28 23 -62z"/>
<path d="M2702 5238 l3 -233 82 -3 c65 -2 82 -6 88 -20 6 -16 160 -464 321
-940 36 -106 63 -199 60 -207 -3 -9 -19 -15 -40 -15 -29 0 -37 -4 -41 -22 -3
-13 -4 -120 -3 -238 l3 -215 568 -3 567 -2 -2 237 -3 238 -43 3 c-25 2 -45 9
-48 17 -3 8 81 273 186 589 l192 576 94 0 94 0 0 235 0 235 -440 0 -440 0 2
-232 3 -233 33 -3 c17 -2 32 -5 32 -6 0 -10 -229 -678 -234 -683 -3 -3 -9 -4
-12 0 -5 5 -198 566 -228 665 -7 19 -4 22 22 22 16 0 35 6 41 14 8 9 11 79 9
232 l-3 219 -433 3 -432 2 2 -232z m816 17 c3 -199 2 -205 -43 -205 -26 0 -35
-4 -35 -16 0 -14 138 -414 190 -549 11 -27 36 -98 56 -157 20 -60 40 -108 44
-108 4 0 29 60 55 133 69 196 96 272 174 490 39 109 71 200 71 202 0 2 -17 5
-37 7 l-38 3 -3 183 -2 182 390 0 390 0 -2 -182 -3 -183 -74 -5 -74 -5 -151
-450 c-219 -648 -265 -788 -268 -805 -2 -11 10 -16 47 -20 l50 -5 0 -185 0
-185 -512 -3 -513 -2 0 190 0 190 40 0 c27 0 40 4 40 13 0 8 -54 173 -121 368
-66 195 -158 464 -204 599 -45 135 -88 257 -94 272 -9 25 -15 27 -73 30 l-63
3 -3 170 c-1 93 0 175 2 182 4 11 81 13 383 11 l378 -3 3 -160z"/>
<path d="M2819 5353 c0 -5 0 -57 0 -118 l0 -110 65 -5 c35 -3 66 -7 69 -10 2
-3 18 -50 36 -105 18 -55 117 -359 221 -675 104 -316 192 -587 195 -602 6 -24
4 -27 -27 -33 -18 -3 -44 -5 -58 -3 l-25 3 -3 -122 -3 -123 451 0 450 0 0 123
0 122 -64 0 c-35 0 -67 3 -71 7 -4 3 10 57 30 120 36 113 357 1095 398 1218
12 36 26 68 32 72 5 4 43 8 83 8 l72 0 0 120 0 120 -320 0 -320 0 0 -120 0
-120 39 0 c78 0 82 25 -71 -423 -77 -227 -167 -492 -200 -589 -33 -98 -63
-178 -68 -178 -4 0 -10 8 -13 18 -3 9 -91 273 -196 586 -105 312 -188 572
-185 577 3 5 27 9 55 9 l49 0 0 120 0 120 -310 0 c-170 0 -310 -3 -311 -7z"/>
<path d="M4980 5182 c0 -211 3 -291 12 -300 17 -17 449 -17 466 0 7 7 12 36
12 65 l0 53 60 0 60 0 -2 -587 -3 -588 -57 -3 -58 -3 0 -239 0 -240 415 0 415
0 0 239 0 240 -52 3 -53 3 -3 588 -2 587 59 0 60 0 3 -62 3 -63 233 -3 232 -2
10 25 c11 28 14 533 4 559 -6 14 -95 16 -910 16 l-904 0 0 -288z m1758 -9 l-3
-248 -185 0 -185 0 -3 63 -3 62 -109 0 -110 0 0 -640 0 -640 55 0 55 0 0 -190
0 -190 -370 0 -370 0 0 190 0 190 65 0 65 0 0 640 0 640 -110 0 -110 0 0 -59
c0 -44 -4 -60 -16 -65 -20 -8 -338 -8 -358 0 -14 5 -16 36 -16 243 0 131 3
241 7 244 3 4 388 7 855 7 l848 0 -2 -247z"/>
<path d="M5106 5338 c-8 -30 -8 -286 0 -315 6 -22 11 -23 120 -23 l114 0 0 49
c0 29 6 53 14 60 9 8 66 11 182 9 l169 -3 3 -702 c2 -702 2 -702 -19 -713 -11
-6 -37 -10 -57 -8 l-37 3 -3 -122 -3 -123 301 0 301 0 -3 123 -3 122 -33 -3
c-18 -2 -46 -1 -62 3 l-30 7 2 706 3 707 180 0 180 0 3 -58 3 -58 122 3 122 3
0 175 0 175 -781 3 -782 2 -6 -22z"/>
<path d="M7022 5238 l3 -233 65 -5 65 -5 3 -660 2 -660 167 -167 168 -168 475
0 475 0 168 168 167 167 0 662 0 662 63 3 62 3 3 233 2 232 -422 0 -423 0 2
-227 c2 -126 3 -231 3 -235 0 -5 29 -8 65 -8 l65 0 0 -554 0 -554 -40 -36 -40
-36 -153 0 -153 0 -37 38 -37 38 0 551 0 552 63 3 62 3 3 233 2 232 -425 0
-425 0 2 -232z m796 0 l-3 -183 -62 -3 -63 -3 0 -580 0 -580 57 -60 56 -59
164 0 164 0 60 57 59 56 0 583 0 583 -62 3 -63 3 -3 183 -2 182 370 0 370 0
-2 -182 -3 -183 -62 -3 -63 -3 0 -682 0 -682 -148 -148 -147 -147 -465 0 -465
0 -147 147 -148 148 0 682 0 683 -59 0 c-33 0 -62 4 -66 10 -4 7 -9 297 -5
353 0 4 167 7 370 7 l370 0 -2 -182z"/>
<path d="M7140 5240 l0 -119 63 -3 62 -3 5 -686 5 -686 139 -147 139 -146 411
0 411 0 145 145 145 145 0 683 c0 443 4 685 10 689 6 4 31 8 58 8 l47 0 0 120
0 120 -300 0 -300 0 0 -120 0 -119 63 -3 62 -3 3 -637 2 -638 -69 -72 -69 -73
-212 0 -212 0 -49 55 c-86 96 -79 33 -77 737 l3 628 58 3 57 3 0 119 0 120
-300 0 -300 0 0 -120z"/>
<path d="M9297 5302 l-167 -167 0 -315 1 -315 172 -178 172 -177 317 0 316 0
31 -26 c31 -26 31 -26 31 -129 l0 -103 -40 -36 -40 -36 -151 0 -151 0 -39 39
-39 39 0 96 0 96 -290 0 -290 0 0 -208 0 -208 168 -167 168 -167 475 0 474 0
168 168 167 167 0 315 0 315 -168 168 -167 167 -316 0 -317 0 -36 40 -36 40 0
105 c0 102 1 105 31 140 l31 35 167 0 167 0 32 -33 c32 -33 32 -33 32 -140 l0
-107 290 0 290 0 0 208 0 207 -168 168 -167 167 -475 0 -475 0 -168 -168z
m1256 -29 l147 -147 0 -178 0 -178 -237 2 -238 3 -3 85 -3 85 -52 53 -51 52
-177 0 -176 0 -52 -54 -51 -55 0 -118 0 -117 57 -58 57 -58 316 0 315 0 148
-148 147 -147 0 -305 0 -305 -148 -148 -147 -147 -460 0 -460 1 -153 147 -152
147 0 178 0 177 240 0 240 0 0 -77 0 -76 58 -59 59 -58 164 0 165 0 57 58 57
58 0 111 0 111 -51 46 -50 45 -320 3 -319 3 -149 155 -150 155 0 305 -1 305
147 147 148 148 465 0 465 0 148 -147z"/>
<path d="M9387 5222 l-137 -137 0 -260 0 -260 143 -143 142 -142 311 0 310 0
40 -42 c98 -104 94 -93 94 -248 l0 -138 -38 -49 c-21 -26 -56 -63 -76 -81
l-38 -33 -204 3 -205 3 -64 70 -64 70 -1 66 c0 51 -4 68 -16 73 -20 8 -298 8
-318 0 -13 -5 -16 -25 -16 -123 l1 -116 139 -142 139 -143 414 0 413 0 142
143 142 144 0 249 -1 249 -142 148 -142 147 -315 0 -316 0 -62 63 -62 63 0
170 0 171 61 54 c34 30 66 57 73 61 6 4 103 8 215 8 l204 0 69 -68 68 -69 0
-72 0 -71 173 2 172 3 3 115 3 114 -143 143 -143 143 -415 0 -415 0 -138 -138z"/>
<path d="M10862 5173 l3 -298 215 -3 c118 -1 225 0 238 3 20 5 22 11 22 65 l0
60 60 0 60 0 -2 -587 -3 -588 -52 -3 c-29 -2 -53 -7 -53 -13 0 -5 -1 -111 -1
-236 l0 -228 416 -3 415 -2 -2 237 -3 238 -55 5 -55 5 -3 588 -2 587 59 0 60
0 3 -62 3 -63 229 -3 c160 -2 233 1 242 9 11 9 14 67 14 300 l0 289 -905 0
-905 0 2 -297z m1756 17 c1 -124 0 -235 -3 -248 l-5 -22 -188 2 -187 3 -3 63
-3 62 -110 0 -109 0 2 -637 3 -638 58 -3 57 -3 -2 -187 -3 -187 -370 0 -370 0
-3 188 -2 187 65 0 65 0 0 640 0 640 -110 0 -109 0 -3 -62 -3 -63 -185 0 -185
0 -3 248 -2 247 852 -2 853 -3 3 -225z"/>
<path d="M10980 5180 l0 -181 118 3 117 3 3 52 c4 66 0 65 202 61 l155 -3 3
-707 2 -707 -32 -6 c-18 -3 -44 -5 -58 -3 l-25 3 0 -120 0 -120 298 -3 297 -2
0 124 0 124 -44 -5 c-23 -3 -53 0 -65 6 l-21 12 2 702 3 702 180 0 180 0 5
-55 5 -55 120 0 120 0 3 178 2 177 -785 0 -785 0 0 -180z"/>
<path d="M12872 5238 l3 -233 43 -3 c48 -4 51 -11 26 -82 -18 -49 -103 -299
-264 -775 -56 -165 -106 -306 -112 -312 -6 -8 -33 -13 -64 -13 l-54 0 0 -240
0 -240 409 0 c364 0 410 2 415 16 8 20 8 428 0 448 -3 9 -16 16 -28 16 -41 0
-47 12 -29 58 l16 42 207 0 207 0 12 -41 c14 -45 9 -56 -29 -61 l-25 -3 -3
-237 -2 -238 415 0 415 0 0 240 0 240 -58 0 c-61 0 -86 12 -95 45 -5 23 -321
996 -342 1059 -24 66 -21 74 28 78 l42 3 3 233 2 232 -570 0 -570 0 2 -232z
m1086 0 l-3 -183 -53 -3 c-50 -3 -53 -5 -46 -25 4 -12 36 -114 71 -227 134
-425 175 -558 203 -645 16 -49 47 -151 70 -225 23 -74 47 -141 52 -147 6 -8
34 -13 69 -13 l59 0 -2 -187 -3 -188 -360 0 -360 0 0 185 0 185 25 3 c14 2 29
7 33 12 10 9 -20 169 -35 188 -12 14 -454 18 -477 3 -15 -10 -64 -179 -55
-193 3 -4 21 -8 40 -8 l34 0 0 -190 0 -190 -360 0 -360 0 0 190 0 190 54 0 55
0 126 388 c70 213 160 488 200 612 101 307 97 278 38 282 l-48 3 -3 183 -2
182 520 0 520 0 -2 -182z"/>
<path d="M13000 5240 l0 -120 55 0 c36 0 57 -5 61 -13 8 -21 -454 -1390 -473
-1405 -10 -7 -31 -11 -48 -10 l-30 3 0 -120 0 -120 288 -3 287 -2 2 110 c0 60
1 116 2 123 1 6 -8 11 -19 10 -34 -5 -85 7 -85 19 0 20 92 302 102 316 8 9 78
12 287 12 152 0 281 -4 287 -8 17 -11 128 -321 119 -331 -4 -3 -30 -6 -58 -7
l-52 -2 -3 -121 -3 -121 291 0 291 0 -3 122 -3 122 -38 1 c-20 1 -40 5 -44 9
-4 4 -112 321 -239 704 -154 465 -227 699 -220 704 6 4 41 8 79 8 l67 0 0 120
0 120 -450 0 -450 0 0 -120z m495 -487 c26 -82 74 -233 107 -336 33 -104 57
-195 54 -203 -4 -12 -41 -14 -213 -14 -114 0 -214 4 -222 9 -12 8 5 69 99 350
62 188 116 341 120 341 5 0 29 -66 55 -147z"/>
<path d="M13427 4658 c-2 -13 -28 -98 -56 -190 -28 -92 -51 -172 -51 -177 0
-13 226 -15 234 -3 6 10 -105 392 -115 392 -3 0 -9 -10 -12 -22z m23 -140 c0
-2 13 -40 29 -85 16 -45 26 -85 23 -88 -3 -3 -32 -5 -64 -5 -66 0 -67 2 -42
77 25 78 36 103 45 103 5 0 9 -1 9 -2z"/>
<path d="M14562 5238 l3 -233 63 -3 62 -3 -2 -587 -3 -587 -62 -3 -63 -3 0
-240 0 -239 423 2 422 3 0 235 0 235 -60 5 -60 5 -3 193 -2 192 148 0 148 0
32 -33 32 -33 2 -399 3 -400 355 0 355 0 3 215 c1 118 0 225 -3 237 -4 19 -13
22 -68 25 l-62 3 -3 243 -2 242 -66 70 -66 70 66 68 66 69 0 274 0 274 -168
168 -167 167 -663 0 -662 0 2 -232z m1455 30 l153 -153 0 -248 0 -249 -70 -77
c-38 -43 -70 -84 -70 -92 0 -8 31 -46 70 -84 l70 -69 2 -261 3 -260 63 -3 62
-3 0 -190 0 -189 -302 2 -303 3 -3 385 -2 385 -47 47 -47 48 -183 0 -183 0 2
-242 3 -243 60 -5 60 -5 3 -165 c1 -91 0 -175 -3 -188 l-5 -22 -368 2 -367 3
-3 187 -2 187 62 3 63 3 3 637 2 637 -62 3 -63 3 -3 183 -2 182 627 0 628 0
152 -152z"/>
<path d="M14670 5240 l0 -119 68 -3 67 -3 3 -697 c2 -793 9 -732 -82 -726
l-51 3 0 -120 0 -120 300 0 300 0 0 120 0 120 -25 -3 c-14 -2 -40 0 -57 3
l-33 6 0 309 0 310 234 0 234 0 63 -59 64 -59 5 -373 5 -374 233 -3 233 -2 -3
122 -3 123 -25 -3 c-14 -2 -40 0 -57 3 l-33 6 0 277 0 277 -97 97 -97 98 30
32 c16 18 60 65 97 105 l67 71 0 214 0 213 -138 138 -137 137 -583 0 -582 0 0
-120z m1007 -171 c87 -77 83 -68 83 -215 0 -147 2 -142 -89 -236 l-45 -48
-233 0 -233 0 0 268 c0 148 3 272 7 275 3 4 107 7 230 7 l223 0 57 -51z"/>
<path d="M15232 4848 l3 -203 168 -3 168 -2 60 51 59 52 0 101 0 102 -53 52
-53 52 -177 0 -177 0 2 -202z m375 120 c33 -32 33 -33 33 -124 0 -91 -1 -93
-31 -118 -41 -35 -55 -38 -199 -34 l-125 3 -3 153 -3 152 148 0 147 0 33 -32z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
